.paper {
	overflow-x: auto;
}

.table {

	:global(.MuiTableCell-sizeSmall) {
		padding: 8px 16px !important;
	}

}
