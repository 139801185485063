.VoucherButtonTextLabel {
	text-align: center;
	line-height: 1.2em !important;
	max-height: 2.4em !important;
	margin: 0 0 0.4em 0;
	overflow: hidden;
}

.VoucherPriceLabel {
	/* position: absolute; */
	/* bottom: 0.2em; */
	text-align: center;
	line-height: 1.2em !important;
	margin: 0;
	overflow: hidden;
}