.paper {
	display: flex;
	min-height: 0;
	min-width: 34rem;
}

.container {
	display: flex;
	width: 100%;
}

.containerCheckoutButtons {

	width: 100%;
	
	>* {
		width: 50%;
	}

	.confirmSaleButton {
		width: 100%;
	}

}

.containerBasket {
	display: grid !important;
	grid-template-rows: 1fr max-content;
	min-height: 0;
	flex-grow: 1;
}

.containerBasketItems {
	overflow-y: auto;
}

.additionalPaymentMethodButton {
	flex-grow: 1;
}

.additionalCheckoutBtn {
	flex-shrink: 1;
	font-size: 1.6rem !important;
	min-width: 0 !important;
	width: 4.5rem !important;
}

@media screen and (min-width: 931px) {
	.paper {
		align-self: start;
		min-height: calc(100vh - 6rem - 3rem);
	}
}