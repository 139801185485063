.paper {
	height: 100%;
	min-height: 0;
}

.container {
	display: grid !important;
	grid-template-rows: max-content max-content 1fr;
	height: 100%;
	min-height: 0;
}

.containerCheckoutButtons {

	>* {
		width: 50%;
	}

	.confirmSaleButton {
		width: 100%;
	}

}

.containerBasket {
	display: grid !important;
	grid-template-rows: 1fr max-content;
	min-height: 0;
}

.containerBasketItems {
	overflow-y: auto;
}
