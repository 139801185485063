.ProductButtonText {
	text-align: right !important;
	width: 100%;
	margin: 1em 0;
}

.ProductButtonTextLabel {
	margin: 0 1em 0 0 !important;
	font-size: 1.1em !important;
	line-height: 1.3em;
}

.sessionTime {
	text-align: center;
}