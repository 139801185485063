.wrapper {
	height: 100%;
	min-height: 0;
	display: grid !important;
	grid-template-rows: max-content auto;
}

.container {
	height: 100%;
	min-height: 0;
}

.containerBasket {
	display: grid !important;
	grid-template-rows: 1fr max-content;
	min-height: 0;
}

.containerBasketItems {
	overflow-y: auto;
}


.containerPayment {
	display: grid !important;
	grid-template-rows: 1fr max-content;
	min-height: 0;
	height: 100%;
}

.containerPaymentItems {
	overflow-y: auto;
}

.confirmSaleButton {
	flex-grow: 1;
}