.root {

	/**
	 * Use Grid so we can control the height of the content 
	 * row and prevent it exceeding the viewport height
	 */
	display: grid !important;
	grid-template-rows: max-content 1fr;
	
	/**
	 * Prevent a CSS Grid blow-out by children!
	 */
	min-height: 0;

	/**
	 * Prevent a CSS Grid blow-out by children - children 
	 * are not allowed to grow to reach their intrinsic height, 
	 * so the height of the content row is constrained and scrolls.
	 */
	>* {
		min-height: 0;
	}

}
