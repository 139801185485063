.root {
	
	display: grid !important;
	grid-gap: 1rem;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr 30rem;

	>:last-child {
		overflow-y: auto;
	}
	
	/** 100vh - height of `UiBar` - padding at top and bottom */
	height: calc(100vh - 6.4rem - 3rem);

	/**
	 * Prevent a CSS Grid blow-out by children!
	 */
	min-height: 0;

}

.columns {
	display: grid !important;
	grid-template-columns: 1fr 4fr !important;
	align-items: center;
	
}

.paymentSection {
	margin: auto;
	width: 100%;
}

.confirmSaleButton {
	height: 4em;
	font-size: 1.3em !important;
	width: 100%;
}