.root {
	background: #ccc;
	font-family: monospace;

	* {
		font-family: inherit !important;
	}
}

.paper {
	max-width: 100%;
	width: 48rem;
}

.pre {
	overflow-x: auto;
}