.chip {
	border-radius: 50%;
}

.noWrap {
	white-space: nowrap;
}

.definite {
	background: lightgreen;
	border: 1px solid green;  
}

.provisional {
	background: yellow;
	border: 1px solid orange;
}

.rejected {
	background: red;
	border: 1px solid black;
}

.cancelled {
	background: pink;
	border: 1px solid red;
}

.unknown {
	background: lightgrey;
	border: 1px solid darkgrey;
}