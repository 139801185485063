.root {
	bottom: 5%;
	left: 50%;
	position: fixed;
	transform: translateX(-50%);
	z-index: 1210; // Basket drawer is 1,200
	border: 3px double #444;

	:global(.MuiAlert-action) {
		align-self: center;
		padding-top: 0;
	}
}
