.root {
	overflow-y: auto;
}

.hopsLogoImg,
.orgLogoImg {
	max-height: 6rem;
	margin: 0 2rem;
}

.logoContainer {
	border-right: 1px solid rgba(0, 0, 0, 0.14);
	margin-right: 0.5rem;
}
