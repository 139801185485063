.typography {
	letter-spacing: 0.05em;
	line-height: 1.5 !important;
	text-transform: none !important;
	padding-left: 1rem;
	padding-right: 1rem;

	/* Constrain the item card label, otherwise the text can overflow */
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.typographyCentred {
	margin: auto !important;
}
