.price {
	flex-shrink: 0;
	text-align: right !important;
}

.quantity {
	flex-shrink: 0;
	width: 3rem;
	word-break: normal !important;
}
