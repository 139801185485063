.root {
    width: 100%;
}

.lookupRoot {
	width: 42rem;
	margin: auto;
}

.lookupContainer {
	width: 42rem;

	:last-child {
		flex-shrink: 0;
	}
}

.voucherSummary {
	min-width: 33rem;
    max-width: 42rem;
}

.voucherDetail {
    text-align: right !important;
}