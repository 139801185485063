.controlRow {

	align-items: center;
	display: flex;
	gap: 1.5rem;

	>:first-child {
		width: 24rem;
	}

	>:last-child {
		flex-grow: 1;
		width: 100%;
	}

}
