.root {
	
	display: grid;
	grid-gap: 3rem;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;
	
	/** 100vh - height of `UiBar` - padding at top and bottom */
	height: calc(100vh - 6.4rem - 3rem);

	/**
	 * Prevent a CSS Grid blow-out by children!
	 */
	min-height: 0;

}
