.clock {
	min-height: unset !important;
	color: rgba(0,0,0,0.6);
	font-size: 1.5em;
	font-weight: 200;
	letter-spacing: .2rem;
}

.clockContent {
	padding: 0.3em 0.6em !important;
}