:root {
	padding: 0;
}

.card {
	padding: 0;
	position: relative;
}

.GridItem {
	padding: 0.25em;
}