.root {

	display: grid !important;
	width: 100%;

	align-items: flex-start;
	gap: 3rem;
	grid-auto-rows: max-content;

	>*:not(.divider) {
		justify-self: center;
	}

}

.newSaleButton {
	height: 4em;
	font-size: 1.3em !important;
	width: 100%;
}
