:global(.orderView) {
	display: flex;
	gap: 1rem;
	flex: 1;
	flex-wrap: wrap;
}

@media screen and (max-width: 930px) {
	:global(.orderView) {
		flex-direction: column;
	}
}