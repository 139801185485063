.root {
	padding: 0 !important;
	text-align: center;
	width: 21rem;
}

.img {
	height: 9rem;
	object-fit: cover;
	width: 100%;
}

.SessionProductButtonTextLabel {
	text-align: center;
	line-height: 1.2em !important;
	max-height: 2.4em !important;
	margin: 0 0 0.4em 0;
	overflow: hidden;
}