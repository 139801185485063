.tabletiny {

	td, th {

		// Barcode
		&:nth-child(1) {
			width: 14rem;
		}

		&:nth-child(2) {
			width: auto;
		}

	}

	td p, th p{
		font-size: 0.95em !important;
	}

}